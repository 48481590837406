<template>
  <div>
    <div class="reply-engine-widget" v-show="setupComplete"
         :style="{ 'background-color': configuration.background_color }">
      <div class="row">
        <div class="col">
          <div v-if="step === 2" class="text-center font-size-20" :style="{ 'color': configuration.main_text_color }">
            <strong v-if="returnType !== 'instaWidget'">Would you like to have access to our software?</strong>
            <strong v-else>Would you like to add this to your website?</strong>
          </div>
          <h3 v-else-if="step === 3" class="h1 text-center" :style="{ 'color': configuration.main_text_color }">Thank
            You
          </h3>
          <h3 v-else class="h1 text-center" :style="{ 'color': configuration.main_text_color }">
            {{ configuration.header_text }}</h3>
        </div>
      </div>

      <div v-if="returnType === 'instaWidget'">
        <div v-if="step === 1" class="row place-search-row">
          <div
              :style="{ 'flex-direction': 'row !important', 'justify-content': 'center', 'gap': '1rem', 'width': 'fit-content' }"
              :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
            <div :style="{ 'width': '50%' }">
              <input type="text" ref="username" id="username" v-model="editor.source.username"
                     :placeholder="instaStreamPlaceholder"
                     :class="{ 'has-error': errors.business }"/>
              <span v-if="errors.business" class="error" :style="{ color: configuration.error_text_color }">{{
                  errors.business
                }}</span>
            </div>

            <div>
              <div v-if="isBusy || forceBusy" v-html="spinner"></div>
              <button v-else class="btn" @click="createInstaProspect"
                      :style="{ 'padding-left': '30px',  'padding-right': '30px', 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
                {{ returnType === 'instaWidget' ? 'Create Widget' : buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="returnType === 'posts'">
        <div v-if="step === 1" class="row place-search-row">
          <div :style="{ 'flex-direction': 'row !important', 'justify-content': 'space-between' }"
               :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
            <div :style="{ 'width': '50%' }">
              <input type="text" ref="placesearch" id="placesearch" :placeholder="placeHolder"
                     :class="{ 'has-error': errors.business }"/>
              <span v-if="errors.business" class="error" :style="{ color: configuration.error_text_color }">{{
                  errors.business
                }}</span>
            </div>
            <select v-model="selectedOption" :class="{ 'has-error': errors.business }"
                    style="width: 30%; border-radius: 7px; padding: 12px;">
              <option value="Famous Quotes">Famous Quotes</option>
              <option value="Educational Content">Educational Content</option>
              <option value="Seasonal/Holiday Posts">Seasonal-Holiday Posts</option>
              <option value="Engagement Posts">Engagement Posts</option>
              <option value="Fun Facts">Fun Facts</option>
              <option value="Humorous Posts">Humorous Posts</option>
              <option value="Quote of the Day">Quote of the Day</option>
              <option value="Interactive Content">Interactive Content</option>
            </select>
            <div>
              <div v-if="isBusy || forceBusy" v-html="spinner"></div>
              <button v-else class="btn" @click="createProspect"
                      :style="{ 'padding-left': '30px',  'padding-right': '30px', 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
                {{ returnType === 'posts' ? 'Generate Post' : buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="step === 1" class="row place-search-row">
          <div :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
            <input type="text" ref="placesearch" id="placesearch" :placeholder="placeHolder"
                   :class="{ 'has-error': errors.business }"/>
            <span v-if="errors.business" class="error" :style="{ color: configuration.error_text_color }">{{
                errors.business
              }}</span>
          </div>
          <div v-if="step === 1" class="col text-center" :class="{ 'center-content': !errors.business }">
            <div v-if="isBusy || forceBusy" v-html="spinner"></div>
            <button v-else class="btn" @click="createProspect"
                    :style="{ 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
              {{ returnType === 'posts' ? 'Generate Post' : buttonText }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="row">
        <div class="col">
          <div class="row">
            <div class="col-lg-3 mt-2">
              <input v-model="form.first_name" type="text" :class="{ 'has-error': errors.first_name }"
                     placeholder="First Name..."/>
              <span v-if="errors.first_name" class="error" :style="{ color: configuration.error_text_color }">{{
                  errors.first_name
                }}</span>
            </div>
            <div class="col-lg-3 mt-2">
              <input v-model="form.email" type="text" :class="{ 'has-error': errors.email }" placeholder="Email..."/>
              <span v-if="errors.email" class="error" :style="{ color: configuration.error_text_color }">{{
                  errors.email
                }}</span>
            </div>
            <div class="col-lg-3 mt-2">
              <!--              <input v-model="form.phone_number" v-mask="'(###) ###-####'" type="tel" :class="{ 'has-error': errors.phone_number }" placeholder="Your Phone Number Here..." />-->
              <vue-tel-input v-model="form.phone_number"
                             :input-options="{ showDialCode: false, tabindex: false, placeholder: 'Phone...' }"
                             @input="onPhoneInput">
                <template slot="arrow-icon"> <span
                    class="vti__dropdown-arrow">&nbsp;▼</span></template>
              </vue-tel-input>
              <span v-if="errors.phone_number" class="error" :style="{ color: configuration.error_text_color }">{{
                  errors.phone_number
                }}</span>
            </div>
            <div class="col-lg-3 mt-2 text-center">
              <div v-if="isBusy" v-html="spinner"></div>
              <button v-else class="btn" @click="createLead"
                      :style="{ 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
                I
                want this
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3" class="row mt-3">
        <div class="col">
          <h1 class="text-center" style="font-weight: normal; font-style: italic"
              :style="{ 'color': configuration.main_text_color }">Please check your email for more details.</h1>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && (isReplyEngine || returnType === 'replies')" class="rwg-app-reply-sample mb-5">
      <div v-for="(review) in placeResultData.reviews" :key="review.id">
        <ReviewReplied :review="review">
          <div class="reply-row" style="margin-left:70px">
            <!--          :typing-speed="i === 0 ? 75 : 50"-->
            <TypeWriter v-if="review.review_response" :text="review.review_response"/>
            <div v-else class="spin">
              <div v-html="spinner" class="d-inline-block"></div>
              <span class="ml-2">Waiting for AI to write a reply</span>
            </div>
          </div>
        </ReviewReplied>
      </div>
    </div>
    <div v-if="step === 2 && returnType === 'image'" class="rwg-app-reply-sample mb-5">
      <div style="text-align: center">
        <img :src="socialImageExample" alt="Social Review Example" style="max-width: 100%; height: auto"
             @load="imageLoaded"/>
      </div>
      <div v-if="!imageCreateFinished" style="text-align: center">
        <div v-html="spinner"></div>
        Creating Your Image
      </div>
      <div v-if="imageCreateFinished" style="text-align: center">
        <a :href="`${downloadRemoveUrl}?image_url=${encodedSocialImageExample}`" target="_blank"
           class="btn btn-link pr-0">Download Sample</a>
      </div>
    </div>
    <div v-if="step === 2 && returnType === 'posts'" class="rwg-app-reply-sample mb-5">
      <div class="modal_content">
        <div class="post_content">
          <div class="post">
            <div v-if="loading" class="loading_div">
              <h2 class="generate">Generating Post!</h2>
              <p><strong>This Can Take Up To 60 Seconds</strong></p>
              <div class="loader"></div>
            </div>
            <div v-else>
              <div class="post_text_wrapper">
                <p id="post_text" class="post_text" v-html="newPost.text"></p>
                <br>
                <p id="post_tags" class="post_tags" v-html="newPost.hashtags"></p>
              </div>
              <div class="post_image_wrapper">
                <img id="post_image" :src="newPost.imageUrl" alt="" class="post_image"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && returnType === 'instaWidget'">
      <div v-if="posts_type === 'profile'">
        <div v-if="editor.layout.show_profile_details">
          <div :style="{ backgroundColor: editor.styles.header_bg, color: editor.styles.header_text }"
               class="profile_details_container">
            <div class="profile_details_left">
              <div v-if="editor.layout.profile_details.profile_image"
                   class="profile_picture_container rounded rounded-circle">
                <img
                    :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                    class="profile_picture">
              </div>
              <div class="profile_names_container">
                <div class="username_container">
                  <h3 v-if="editor.layout.profile_details.name" class="name"
                      :style="{ color: editor.styles.header_text }">{{ profile.name }}</h3>
                </div>
                <div class="username_container">
                  <h3 v-if="editor.layout.profile_details.username" class="username"
                      :style="{ color: editor.styles.header_text }">
                    {{ profile.username }}
                  </h3>
                </div>

              </div>
            </div>
            <div class="profile_insights_container">
              <p v-if="editor.layout.profile_details.post_count" class="insight_count_tag"
                 :style="{ color: editor.styles.header_text }">
                <strong class="post_count" :style="{ color: editor.styles.header_text }">{{
                    formatNumber(profile.media_count)
                  }}</strong>
                posts
              </p>
              <p v-if="editor.layout.profile_details.followers" class="insight_count_tag"
                 :style="{ color: editor.styles.header_text }">
                <strong class="followers_count"
                        :style="{ color: editor.styles.header_text }">{{
                    formatNumber(profile.followers_count)
                  }}</strong>
                followers
              </p>
              <p v-if="editor.layout.profile_details.following" class="insight_count_tag"
                 :style="{ color: editor.styles.header_text }">
                <strong class="following_count" :style="{ color: editor.styles.header_text }">{{
                    formatNumber(profile.follows_count)
                  }}</strong>
                following
              </p>
            </div>
            <div class="follow_button_container">
              <a v-if="editor.layout.profile_details.follow_button" class="follow_button"
                 style="display: flex; align-items: center; gap: .5rem;"
                 :href="'https://instagram.com/' + profile.username"
                 :style="{ backgroundColor: editor.styles.follow_button_bg, color: editor.styles.follow_button_text }"
                 target="_blank">
                <uil-instagram/>
                Follow
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="posts_container grid_layout_posts_container"
           :style="{
                gridTemplateColumns: `repeat(${editor.layout.columns}, 1fr)`,
                gap: editor.layout.padding + 'px',
              }">
        <div v-for="post in visiblePosts" :key="post.id" @click="post_action(post)">
          <div v-if="editor.post_style.post_style === 'image_card' && post.media_url" class="post image_card">
            <div class="media_wrapper">
              <!-- Media Icon -->
              <uil-play v-if="post.media_type === 'VIDEO'" class="media_icon"/>
              <uil-layers v-if="post.media_type == 'CAROUSEL_ALBUM'" class="media_icon"/>

              <!-- Media Content -->
              <img v-if="post.media_type === 'IMAGE' && post.media_prodxuct_type === 'FEED'" :src="post.media_url"
                   class="post_image" alt="Post image">
              <img v-else-if="post.media_type === 'IMAGE'" :src="post.media_url" class="post_image" alt="Post image">
              <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src="post.media_url" class="post_image"
                   alt="Post image">

              <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'" :src="post.media_url"
                     class="post_image"></video>
              <video v-else-if="post.media_type === 'VIDEO'" :src="post.media_url" class="post_image"></video>
            </div>

            <div class="post_insights_container">
              <span class="d-flex gap-5">
                <p class="post_like">
                  <uil-heart-alt/>{{ formatNumber(post.like_count) }}
                </p>
                <p class="post_like ml-3">
                  <uil-comment/>{{ formatNumber(post.comments_count) }}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showLoadMore && editor.layout.layoutStyle === 'grid'" class="load_more_container">
        <button @click="loadMore"
                class="load_more_button"
                :style="{ backgroundColor: editor.styles.load_more_button_bg, color: editor.styles.load_more_button_text }"
        >Load More
        </button>
      </div>
    </div>
  </div>
</template>


<style scoped>

* {
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.fetch_loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layouts_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


.profile_details_container {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  .profile_details_left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .profile_picture_container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .profile_picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .profile_names_container {
    justify-self: flex-start;

    .name {
      margin-bottom: .2rem;
      color: #222
    }

    .username {
      font-size: 16px;
      font-weight: 400;
      margin-block: 0;
      color: #555;
    }

    .username::before {
      content: '@';
    }
  }


  .follow_button {
    background: #007FFF;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 4px;
    border: none;
  }

  .profile_insights_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .insight_count_tag {
      //display: inline-block; margin-inline: .5rem; font-size: 18px; margin-bottom: 0; color: #555; line-height: 1; text-transform: capitalize;

      strong {
        display: block;
        text-align: center;
        color: #222;
        font-size: 22px;
        margin-bottom: .2rem;
      }
    }
  }

}

.posts_container {
  padding: 1rem 2rem;
}

.grid_layout_posts_container {

  display: grid;
  //grid-template-columns: repeat( , 1fr); justify-content: center; gap: .5rem;
}

.post {
  cursor: pointer;
}

.media_wrapper {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  position: relative;
  background: #0a0a0a;
  overflow: hidden;

  .media_icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
  }
}

.post.image_card:hover .post_insights_container {
  display: grid;
  place-items: center;
}

.post.image_card {
  width: 100%;
  height: 100%;
  position: relative;

  img.post_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video.post_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}
.post_insights_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;

  .post_like {
    font-size: 1.5rem;
    color: #fff
  }
}

.load_more_container {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;

  .load_more_button {
    background: #007FFF;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 4px;
    border: none;
    margin-inline: auto;
    margin-block: .5rem;
    font-size: 1.1rem;

  }
}


.instagram-widget.dark {
  background: #1b1b1b !important;

  * {
    color: #fff !important;
  }

  .post {
    border: 1px solid #1f1d10;
  }

  .post * {
    color: #fff !important;
  }

  .media_wrapper {
    background: #0a0a0a;
  }

  .media_icon {
    color: #fff;
  }
}

.modal_posts_container .post.expanded {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #cecece;

  .post_header {
    display: flex;
    gap: .3rem;
    padding-block: .5rem;
    cursor: pointer;

    .profile_image {
      width: 40px;
      border-radius: 50%;
    }

    .username {
      font-size: .9rem;
      font-weight: 700;
      color: #111;
    }

    .follow_btn_post {
      border: none;
      background: transparent;
      color: #0e8dc7;
      font-size: .9rem;
    }
  }

  .post_image_container {
    width: 100%;

    img.post_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video.post_image {
      width: 100%;
      height: 100%;
    }
  }

  .media_wrapper {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    position: relative;

    .media_icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 700;
    }
  }

  .post_footer {

    .post_insights_container {
      p {
        margin-block: 0.5rem;
        font-size: 1.2rem;
        color: #111;
      }
    }

    .post_caption_container {
      .caption {
        color: #111;
        //overflow: hidden; //display: -webkit-box; //-webkit-line-clamp: 3; //line-clamp: 3; //-webkit-box-orient: vertical;
      }
    }

  }
}

li:has( .profile_details) {
  justify-content: flex-start !important;

  .profile_details {
    width: 100%;
  }

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }
}

.instagram-widget.dark {
  background: #1b1b1b !important;

  * {
    color: #fff !important;
  }

  .post {
    border: 1px solid #1f1d10;
  }

  .post * {
    color: #fff !important;
  }

  .media_wrapper {
    background: #0a0a0a;
  }

  .media_icon {
    color: #fff;
  }
}

.dark .modal_posts_container {
  background: #333;

  * {
    color: #fff !important;
  }
}
@media (max-width: 768px){
  .post_insights_container{
    display: none !important;
  }
  .media_icon{
    font-size: 1.5rem !important;
    top: .3rem !important;
    right: .3rem !important;
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 2rem !important;
  }
  .profile_picture_container {
    width: 60px !important;
    height: 60px !important;
  }
  .profile_names_container{
    .name{
      font-size: 22px !important;
    }
  }
  .profile_details_container{
    flex-wrap: wrap;
    justify-content: space-around !important;
    gap: 1.5rem !important;

    .follow_button {
      padding: .3rem .8rem !important;
      gap: .3rem !important;
    }
  }
  strong{
    font-weight: 500;
  }
  .profile_insights_container {
    order: 3 !important;
    width: 100%;
    justify-content: space-around !important;
  }
  .posts_container{
    padding: .3rem !important;
  }
  .grid_layout_posts_container{
    padding: .3rem !important;
    grid-template-columns: 1fr !important;
    .post_insights_container{
      display: block !important;
    }
    .post{
      margin-bottom: 1.5rem !important;
      border-bottom: 1px solid #1122113d;
    }
  }
}
</style>

<script>
/* eslint-disable */
import Vue from 'vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ReviewReplied from "../../../src/components/Common/ReviewReplied.vue";
import {countryToAlpha2} from "country-to-iso";
import axios from "axios";
import {UilPlay} from '@iconscout/vue-unicons'
import {UilLayers} from '@iconscout/vue-unicons'
import {UilComment} from '@iconscout/vue-unicons'
import {UilHeartAlt} from '@iconscout/vue-unicons'
import {UilInstagram} from '@iconscout/vue-unicons'

axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? window.location.origin.includes('staging.') ? 'https://api.replyengine.io/' : 'https://api.replyengine.io/' : 'https://api.replyengine.io/') + 'api/v1';
import VueScript2 from "vue-script2";
import TypeWriter from '../../../src/components/Common/TypeWriter.vue';
import {VueMaskDirective} from 'v-mask'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
Vue.directive('mask', VueMaskDirective);
Vue.use(VueTelInput);
const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
};
export default {
  components: {
    VueGoogleAutocomplete,
    TypeWriter,
    ReviewReplied,
  },
  props: {
    startingStep: {
      type: Number,
      default: 1
    },
    // eslint-disable-next-line vue/prop-name-casing
    agencyId: {
      type: [Number, String],
      required: true
    },
    headerText: {
      type: String,
      default: 'Search For Your Business'
    },
    // eslint-disable-next-line vue/prop-name-casing
    googleApiKey: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#0e8dc7'
    },
    mainTextColor: {
      type: String,
      default: '#ffffff'
    },
    primaryButtonBackgroundColor: {
      type: String,
      default: '#34A853'
    },
    primaryButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    secondaryButtonBackgroundColor: {
      type: String,
      default: '#FFBE0B'
    },
    secondaryButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    copyAreaBackgroundColor: {
      type: String,
      default: '#EFF3F7'
    },
    copyAreaTextColor: {
      type: String,
      default: '#596482'
    },
    copyButtonBackgroundColor: {
      type: String,
      default: '#537BBA'
    },
    copyButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    spinnerColor: {
      type: String,
      default: '#000000'
    },
    errorTextColor: {
      type: String,
      default: '#4e0202'
    },
    defaultMapQuery: {
      type: String,
      default: ''
    },
    qrText: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: 'Start Typing To Find Your Business'
    },
    postPlaceHolder: {
      type: String,
      default: 'Famous Quote'
    },
    manuallyCreated: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: "Submit"
    },
    forceBusy: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    },
    isTapEngine: {
      type: Boolean,
      default: false
    },
    isReplyEngine: {
      type: Boolean,
      default: false
    },
    isSocialReview: {
      type: Boolean,
      default: false
    },
    isSocialFusion: {
      type: Boolean,
      default: false
    },
    isInstaStream: {
      type: Boolean,
      default: false
    },
    isReviewSurge: {
      type: Boolean,
      default: false
    },
    returnType: {
      type: String,
      default: "widget"
    }
  },
  components: {
    vSelect,
    UilPlay,
    UilLayers,
    UilComment,
    UilHeartAlt,
    UilInstagram,
  },
  data() {
    return {
      instaStreamPlaceholder: "Enter @username to create your widget",
      createdBusinessId: null,
      profile: {
        username: '',
        name: '',
        profile_picture_url: '',
        followers_count: '',
        follows_count: '',
        media_count: '',
      },
      posts: [{
        'id': '',
        'like_count': '',
        'comments_count': '',
        'caption': '',
        'media_url': '',
      }],
      posts_type: 'profile',
      modalPost: {
        'id': '',
        'like_count': '',
        'comments_count': '',
        'caption': '',
        'media_url': '',
      },
      editor: {
        source: {
          username: '',
          type: 'username',
        },
        layout: {
          layoutStyle: 'grid',
          rows: 2,
          columns: 3,
          show_profile_details: true,
          profile_details: {
            profile_image: true,
            username: true,
            name: true,
            follow_button: true,
            post_count: true,
            followers: true,
            following: true,
          },
          padding: 5,
        },
        post_style: {
          post_style: 'image_card',
          post_details: {
            profile_image: true,
            username: true,
            likes_count: true,
            comment_count: true,
            caption: true,
          },
          post_action: 'instagram'
        },
        editing: 'layout',
        styles: {
          theme: 'light',
          header_bg: '#ffffff',
          header_text: '#000000',
          follow_button_bg: '#0081ff',
          follow_button_text: '#ffffff',
          load_more_button_bg: '#0081ff',
          load_more_button_text: '#ffffff',

        }
      },
      gettingWidget: false,
      showSocialPostModal: false,
      loading: false,
      showModal: false,
      newPost: {
        text: '',
        imageUrl: '',
        hashtags: '',
        id: ''
      },
      options: ['Famous Quotes', 'Educational Content', 'Seasonal-Holiday Posts', 'Engagement Posts', 'Fun Facts', 'Humorous Posts', 'Quote of the Day', 'Interactive Content'],
      selectedOption: 'Famous Quotes',  // this will store the selected option
      postPlaceHolder: "Select a content type", // example placeholder
      errors: {
        business: false
      },
      configuration: {
        error_text_color: '#FF0000' // example error text color
      },
      phone_valid: false,
      step: this.startingStep,
      street: '',
      place: null,
      addressData: null,
      google_api_key: this.googleApiKey,
      reduced_address: null,
      placeResultData: {
        place_id: '',
        url: '',
        reviews: []
      },
      autocompleteText: '',
      placeFields: ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', 'website', 'formatted_phone_number', 'reviews', 'rating', 'user_ratings_total'],
      scriptLoaded: false,
      mapReady: false,
      isBusy: false,
      setupComplete: false,
      showCopied: false,
      imageCreateFinished: false,
      mapQuery: this.defaultMapQuery,
      errors: {},
      unique_id: '',
      user_id: this.userId,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        business_name: '',
        business_phone: '',
        business_website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        lat: '',
        lng: '',
        place_id: '',
        google_maps_url: '',
        agency_id: '',
        types: [],
        origin: '',
        sample_reviews: [],
        manually_created: false,
        is_tap_engine: this.isTapEngine,
        is_reply_engine: this.isReplyEngine,
        is_social_review: this.isSocialReview,
        is_social_fusion: this.isSocialFusion,
        is_insta_stream: this.isInstaStream,
        is_text_requester: this.isReviewSurge,
        return_type: this.returnType,
      },
      configuration: {
        background_color: this.backgroundColor,
        main_text_color: this.mainTextColor,
        primary_button_background_color: this.primaryButtonBackgroundColor,
        primary_button_text_color: this.primaryButtonTextColor,
        copy_area_background_color: this.copyAreaBackgroundColor,
        copy_area_text_color: this.copyAreaTextColor,
        copy_button_background_color: this.copyButtonBackgroundColor,
        copy_button_text_color: this.copyButtonTextColor,
        error_text_color: this.mainTextColor,
        header_text: this.headerText,
        qr_text: this.qrText
      },
      oneReview: null,
      additionalPostsToShow: 0
    }
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue === 2 && this.returnType === 'posts') {
        this.generatePost();
      }

      if (newValue === 2 && this.returnType === 'instaWidget') {
        this.createWidget();
      }
    },
    'form.first_name': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('first_name')) {
        Vue.delete(this.errors, 'first_name');
      }
    },
    'form.last_name': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('last_name')) {
        Vue.delete(this.errors, 'last_name');
      }
    },
    'form.email': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('email')) {
        Vue.delete(this.errors, 'email');
      }
    },
    'form.phone_number': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('phone_number')) {
        Vue.delete(this.errors, 'phone_number');
      }
    },
    startingStep: function (newVal) {
      this.step = newVal;
    },
    backgroundColor: function (newVal) {
      this.configuration.background_color = newVal;
    },
    mainTextColor: function (newVal) {
      this.configuration.main_text_color = newVal;
    },
    primaryButtonBackgroundColor: function (newVal) {
      this.configuration.primary_button_background_color = newVal;
    },
    primaryButtonTextColor: function (newVal) {
      this.configuration.primary_button_text_color = newVal;
    },
    copyAreaBackgroundColor: function (newVal) {
      this.configuration.copy_area_background_color = newVal;
    },
    copyAreaTextColor: function (newVal) {
      this.configuration.copy_area_text_color = newVal;
    },
    copyButtonBackgroundColor: function (newVal) {
      this.configuration.copy_button_background_color = newVal;
    },
    copyButtonTextColor: function (newVal) {
      this.configuration.copy_button_text_color = newVal;
    },
    errorTextColor: function (newVal) {
      this.configuration.error_text_color = newVal;
    },
    headerText: function (newVal) {
      this.configuration.header_text = newVal;
    },
    qrText: function (newVal) {
      this.configuration.qr_text = newVal;
    }
  },
  computed: {
    filteredPosts(){
      return this.posts.filter(post => post.media_url);
    },
    visiblePostCount() {
      return (this.editor.layout.columns * this.editor.layout.rows) + this.additionalPostsToShow;
    },
    visiblePosts() {
      const maxPosts = (this.editor.layout.columns * this.editor.layout.rows) + this.additionalPostsToShow;
      const filteredPosts = this.posts.filter(post => post.media_url);
      return filteredPosts.slice(0, maxPosts);
    },
    showLoadMore() {
      console.log(this.visiblePostCount)
      console.log(this.posts.length)
      return this.visiblePostCount < this.posts.length;
    },
    addressString() {
      let address_string = '';
      if (this.placeResultData.hasOwnProperty('street_number') && this.placeResultData.hasOwnProperty('route')) {
        address_string = this.placeResultData.street_number + ' ' + this.placeResultData.route;
        if (this.placeResultData.hasOwnProperty('subpremise')) {
          address_string += ' ' + this.placeResultData.subpremise;
        }
      } else if (this.placeResultData.hasOwnProperty('formatted_address')) {
        address_string = this.placeResultData.formatted_address.split(',')[0]
      }
      return address_string;
    },
    socialImageExample() {
      if (this.placeResultData.reviews.length) {
        const sortedReviews = this.placeResultData.reviews.sort((a, b) => {
          // Sort by rating in descending order
          if (a.rating !== b.rating) {
            return b.rating - a.rating;
          }
          // If ratings are equal, sort by timestamp in descending order
          return b.time - a.time;
        });
        const review = sortedReviews[0];
        this.oneReview = review;
        const review_text = review.text;
        const review_rating = review.rating;
        const author_title = review.author_name;
        const business_name = this.placeResultData.name;
        const phone_number = this.placeResultData.formatted_phone_number;
        const author_image = review.profile_photo_url;
        return `https://api.replyengine.io/api/v1/sample-social-image?review_rating=${encodeURI(review_rating)}&text=${encodeURI(review_text)}&reviewer=${encodeURI(author_title)}&business_name=${encodeURI(business_name)}&business_phone=${encodeURI(phone_number)}&icon=${author_image}`;
      }
    },
    encodedSocialImageExample() {
      return btoa(this.socialImageExample);
    },
    downloadRemoveUrl() {
      return (process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? "https://api.showmyrep.io/" : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/remote-download-image';
    },
    customLink() {
      return (process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? window.location.origin : 'https://replyengine.io') + '/r/' + this.unique_id;
    },
    spinner() {
      return `<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="${this.spinnerColor}">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"/>
            </path>
          </g>
        </g>
      </svg>`;
    }
  },

  mounted() {
    if (!this.googleApiKey) {
      // We are being loaded from outside of site and need to get the API Key
      axios.post('/agency-data', {agency_id: this.agencyId})
          .then((res) => {
            let embed_settings = res.data.data.embed_form_settings;
            console.log(res.data.data)
            if (this.returnType === 'image') {
              embed_settings = res.data.data.social_review_embed_form_settings;
            }
            if (this.returnType === 'posts') {
              embed_settings = res.data.data.social_fusion_embed_form_settings;
            }
            if (this.returnType === 'instaWidget') {
              embed_settings = res.data.data.insta_stream_embed_form_settings;
            }
            console.log(embed_settings)
            this.configuration.header_text = embed_settings.title;
            this.configuration.background_color = embed_settings.background_color;
            this.configuration.main_text_color = embed_settings.main_text_color;
            this.configuration.primary_button_background_color = embed_settings.primary_button_background_color;
            this.configuration.primary_button_text_color = embed_settings.primary_button_text_color;
            this.configuration.secondary_button_background_color = embed_settings.secondary_button_background_color;
            this.configuration.secondary_button_text_color = embed_settings.secondary_button_text_color;
            this.configuration.copy_area_background_color = embed_settings.copy_area_background_color;
            this.configuration.copy_area_text_color = embed_settings.copy_area_text_color;
            this.configuration.copy_button_background_color = embed_settings.copy_button_background_color;
            this.configuration.copy_button_text_color = embed_settings.copy_button_text_color;
            this.configuration.qr_text = embed_settings.qr_text;
            this.google_api_key = res.data.data.google_api_key;
            VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
              this.initMaps();
              this.setup();
            })
          })
          .catch((error) => {
            //eslint-disable-next-line no-console
            console.log(error)
          })
    } else {
      this.google_api_key = this.googleApiKey;
      VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
        this.initMaps();
        this.setup();
      })
    }
    this.form.agency_id = this.agencyId;
  },

  methods: {
    async createWidget() {
      const username = (this.editor.source.username).replace(/@/g, ''); // Handle nullish values safely
      this.gettingWidget = true;
      const response = await axios.post('/create-lead-widget', {
        editor: this.editor,
        widgetTitle: username,
        businessId: this.agencyId
      });

      this.editor = JSON.parse(response.data.settings.widget_settings);
      this.profile = response.data.businessDetails;
      this.posts = response.data.businessDetails.media.data

    },
    generatePrompt() {
      const today = new Date();
      const dateStr = today.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});

      const categoryPrompts = {
        'Famous Quotes': 'Generate a unique famous quote relevant to business or general life. Ensure the quote is distinct from previous responses by categorizing it under themes like "leadership," "innovation," "perseverance," "teamwork," or "success." Only one quote, not unknown THE POPULAR QUOTE. Please don’t add any other text just a quote. The quote should not be of UNknown author, author should be well known.',
        'Educational Content': 'Create a piece of educational content that provides useful and unique information. Ensure the response covers a different topic or aspect, such as "history," "science," "technology," "health," or "personal development."',
        'Seasonal-Holiday Posts': `Generate a unique greeting or message for a holiday or special event. Check what event is today and according to that create post. Today's date is ${dateStr}`,
        'Engagement Posts': 'Create a unique question or prompt to engage the audience. Ensure the question is distinct by focusing on various themes like "daily habits," "personal goals," "favorite hobbies," "memorable experiences," or "current events."',
        'Fun Facts': 'Generate an interesting and unique fun fact. Ensure the fact is different by categorizing it under themes like "animals," "history," "space," "human body," or "technology."',
        'Humorous Posts': 'Create a funny and unique meme or joke. Ensure the response is distinct by focusing on different themes such as "workplace humor," "daily life," "technology jokes," "relationship humor," or "puns."',
        'Quote of the Day': 'Generate a unique motivational or thought-provoking quote for the day. Ensure the quote is distinct and categorized under themes like "inspiration," "wisdom," "courage," "creativity," or "resilience."',
        'Interactive Content': 'Create a unique piece of interactive content like a poll or challenge. Ensure the response offers a different activity by focusing on various themes like "preferences," "trivia," "challenges," "surveys," or "quizzes."'
      };

      let prompt = categoryPrompts[this.selectedOption];

      return prompt;
    },
    generatePost() {
      const prompt = this.generatePrompt();
      this.loading = true;

      axios.post('/generate-widget-post', {
        prompt: prompt,
        user_id: this.user_id,
        agency_id: this.agencyId,
      }).then((res) => {
        this.newPost = {
          text: res.data.data.text,
          hashtags: res.data.data.hashtags,
          imageUrl: res.data.data.imageUrl,
          date: new Date().toLocaleString(),
        };
      }).catch((err) => {
        console.log(err);
      })
          .finally(() => {
            this.loading = false;
          });
    },
    imageLoaded() {
      console.log(this.imageCreateFinished);
      this.imageCreateFinished = true;
      console.log(this.imageCreateFinished);
    },
    onPhoneInput(input, phoneObject) {
      this.phone_valid = typeof phoneObject == 'object' && phoneObject.valid;
    },
    setup() {

      this.autocomplete = new google.maps.places.Autocomplete(
          this.$refs.placesearch,
          {
            types: ['establishment']
          }
      );
      //this.autocomplete.setFields(this.placeFields);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
      this.setupComplete = true;
      if (this.searchText !== '') {
        this.$refs.placesearch.value = this.searchText;
      }
    },
    downloadImage(imageUrl) {
      var link = document.createElement('a');
      link.href = imageUrl;
      link.target = '_blank';
      link.download = 'socialreview_sample.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * When a place changed
     */
    onPlaceChanged() {
      Vue.delete(this.errors, 'business');
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place);
        return;
      }
      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.selected(this.formatResult(place), place);
        // update autocompleteText then emit change event
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      this.showCopied = true;
      setTimeout(() => this.showCopied = false, 2000)
    },
    resetErrors() {
      this.errors = {};
    },
    createProspect() {
      this.isBusy = true;
      // this.resetErrors();
      if (this.addressData === null) {
        this.errors.business = "Please select your business first";
      }
      if (this.addressData !== null && (!this.placeResultData.hasOwnProperty('place_id') || (this.placeResultData.hasOwnProperty('place_id') && this.placeResultData.place_id === ""))) {
        this.errors.business = "Sorry, but we could not find a place ID identifier for your business";
      }
      if (Object.keys(this.errors).length) {
        this.isBusy = false;
        return
      }
      this.reduced_address = this.placeResultData.address_components.reduce((seed, {long_name, types}) => {
        types.forEach(t => {
          seed[t] = long_name;
        });
        return seed;
      }, {});
      const country = countryToAlpha2(this.addressData.country);
      let city = '';
      let address = '';
      const state = this.addressData.administrative_area_level_1;
      const zip = this.addressData.postal_code;
      if (country === 'US') {
        city = this.reduced_address.hasOwnProperty('neighborhood') ? this.reduced_address.neighborhood : this.addressData.locality;
        address = this.addressString;
      } else {
        city = this.reduced_address.hasOwnProperty('postal_town') ? this.reduced_address.postal_town : this.reduced_address.hasOwnProperty('locality') ? this.addressData.locality : null
        /* if(this.reduced_address.hasOwnProperty('premise')){
          address += this.reduced_address.premise + ' ';
        } */
        if (this.reduced_address.hasOwnProperty('street_number')) {
          address += this.reduced_address.street_number + ' ' + this.reduced_address.route;
        } else if (this.reduced_address.hasOwnProperty('subpremise') && this.reduced_address.hasOwnProperty('premise') && this.reduced_address.hasOwnProperty('route')) {
          address += this.reduced_address.subpremise + ', ' + this.reduced_address.premise + ', ' + this.reduced_address.route;
        } else {
          const address_pieces = this.placeResultData.formatted_address.split(', ');
          let new_address_array = [];
          address_pieces.every(piece => {
            if (piece.startsWith(city) || piece.startsWith(state) || piece.startsWith(this.addressData.country) || piece.startsWith(zip)) {
              return false;
            } else {
              new_address_array.push(piece);
              return true;
            }
          });
          address += new_address_array.join(', ');
        }
      }
      this.form.business_name = this.placeResultData.name;
      this.form.business_phone = this.placeResultData.formatted_phone_number;
      this.form.business_website = this.placeResultData.website;
      this.form.address = address;
      this.form.city = city;
      this.form.state = state;
      this.form.zip = zip;
      this.form.country = country;
      this.form.lat = this.addressData.latitude;
      this.form.lng = this.addressData.longitude;
      this.form.place_id = this.placeResultData.place_id;
      this.form.google_maps_url = this.placeResultData.url;
      this.form.types = this.placeResultData.types;
      this.form.origin = window.location.href;
      this.form.manually_created = this.manuallyCreated;
      this.form.is_reply_engine = this.isReplyEngine;
      this.form.is_tap_engine = this.isTapEngine;
      this.form.is_social_fusion = this.isSocialFusion;
      this.form.is_insta_stream = this.isInstaStream;
      this.form.is_text_requester = this.isReviewSurge;
      const win = window;
      axios.post('/create-lead', this.form)
          .then((res) => {
            this.unique_id = res.data.data.unique_id;
            if (this.manuallyCreated) {
              this.$emit('prospectCreated', res.data.data);
            } else {
              this.step++;
              this.placeResultData.reviews.forEach(review => {
                axios.post('/sample-replies', {
                  review: review.text,
                  reviewers_name: review.author_name,
                  review_stars: review.rating + " out of 5 stars. "
                }).then((res) => {
                  console.log(res);
                  if (
                      res.data.hasOwnProperty('reply')
                  ) {
                    const reply = res.data.reply;
                    review.review_response = reply.replace('/\n/', '*');
                  } else {
                    console.error(res.data.error);
                  }

                });
              });
            }
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            //eslint-disable-next-line no-console
            console.log(error)
          });
    },
    createInstaProspect() {
      this.isBusy = true;
      const username = (this.editor.source.username).replace(/@/g, '');
      let r = (Math.random() + 1).toString(36).substring(7);
      this.placeResultData.place_id = username + "_" + r;

      axios.post('/verify-username', { username })
          .then((res) => {
            if (!res.data.success) {
              Vue.$toast.open({
                message: res.data.message || 'Invalid Username',
                type: 'error',
                duration: 5000,
              });
              this.isBusy = false;
              return;
            }

            // Proceed with the next steps if the username is valid
            this.form.business_name = username;
            this.form.business_phone = '';
            this.form.business_website = 'https://instagram.com/' + username;
            this.form.address = '';
            this.form.country = 'US';
            this.form.city = '';
            this.form.state = '';
            this.form.zip = '';
            this.form.lat = '';
            this.form.lng = '';
            this.form.place_id = this.placeResultData.place_id;
            this.form.google_maps_url = '';
            this.form.types = '';
            this.form.origin = '';
            this.form.manually_created = this.manuallyCreated;
            this.form.is_reply_engine = this.isReplyEngine;
            this.form.is_tap_engine = this.isTapEngine;
            this.form.is_social_fusion = this.isSocialFusion;
            this.form.is_insta_stream = this.isInstaStream;
            this.form.is_text_requester = this.isReviewSurge;

            axios.post('/create-lead', this.form)
                .then((res) => {
                  this.unique_id = res.data.data.unique_id;
                  if (this.manuallyCreated) {
                    this.$emit('prospectCreated', res.data.data);
                  } else {
                    this.step++;
                    this.createdBusinessId = res.data.business_id;
                  }
                  this.isBusy = false;
                })
                .catch((error) => {
                  Vue.$toast.open({
                    message: 'Failed to create lead',
                    type: 'error',
                    duration: 5000,
                  });
                  this.isBusy = false;
                  console.error(error);
                });
          })
          .catch((error) => {
            Vue.$toast.open({
              message: 'Failed to verify username',
              type: 'error',
              duration: 5000,
            });
            this.isBusy = false;
            console.error(error);4
          });
    },
    checkErrors() {
      if (this.form.first_name === '') {
        this.errors.first_name = "Please enter First Name";
      }
      if (!/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.form.email)) {
        this.errors.email = "Please enter a valid email"
      }
      if (!this.phone_valid) {
        this.errors.phone_number = "Please enter a valid phone number"
      }
    },
    createLead() {
      this.isBusy = true;
      this.resetErrors();
      this.checkErrors();
      if (this.form.business_name === '') {
        // Somehow they are here and a prospect was not created
        this.createProspect();
      }
      if (Object.keys(this.errors).length) {
        this.isBusy = false;
        return
      }
      axios.post('/update-lead', this.form)
          .then(() => {
            this.step++;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            //eslint-disable-next-line no-console
            console.log(error);
            if (error.response) {
              console.log(error.response.data);
              if (error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('email')) {
                this.errors.email = error.response.data.errors.email[0];
              }
            }
          })
    },
    initMaps() {
      this.scriptLoaded = true;
      // this.$refs.address.focus();
    },
    selected(addressData, placeResultData) {
      this.addressData = addressData;
      placeResultData.reviews.forEach((review, index) => {
        review.id = index;
        review.review_response = null;
      });
      this.placeResultData = placeResultData;
      console.log(this.placeResultData);
      this.mapQuery = encodeURIComponent(placeResultData.name + ' ' + placeResultData.formatted_address);
    },
    updateAddress() {
      if (this.placeResultData) {
        this.$refs.address.update(this.placeResultData.name + ' ' + this.placeResultData.formatted_address);
      }
    },
    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType]) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }
      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData
    },
    formatNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return value; // Return as is if it's not a valid number
      }

      const thresholds = [
        {value: 1e9, suffix: 'B'},  // Billion
        {value: 1e6, suffix: 'M'},  // Million
        {value: 1e3, suffix: 'K'}   // Thousand
      ];

      for (const {value: threshold, suffix} of thresholds) {
        if (value >= threshold) {
          return (value / threshold).toFixed(1) + suffix;
        }
      }

      return value.toString(); // Return the number as a string if less than 1000
    },
    post_action(post) {
      this.modalPost = post;
      if (this.editor.post_style.post_action === 'instagram') {
        window.open(post.permalink, '_blank');
      } else {
        this.showPostModal = true;
      }
    },
    loadMore() {
      this.additionalPostsToShow += this.editor.layout.columns;
    },
  },
}
</script>
<style lang="scss">
@import "../../src/assets/scss/review-form.scss";

.reply-row {
  margin: 25px 0 0 0;

  strong {
    margin-bottom: 5px;
    display: block
  }
}

.modal_content {

  .post_content {
    max-width: 28rem;
    margin-inline: auto;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      gap: .5rem;
    }

    .post_text_wrapper {
      text-align: left;
      padding: .5rem .8rem;

      p {
        margin: 0;
      }
    }

    .post_image_wrapper {
      border: 1px solid gray;

      .post_image {
        width: 100%;
      }
    }

    .edit_post_button {
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        position: absolute;
        left: 102%;
        top: 0
      }
    }
  }

  .post_image {
    height: 300px !important;
  }

  .regenrate_buttons_container {
    margin-bottom: .5rem;
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .regenerate_btn {
      margin-inline: .3rem;
      border: none;
      background: #06b050;
      color: #fff;
      padding: .3rem 1.5rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .social_media_buttons_container {
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .social_post_btn {
      margin-inline: .3rem;
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem .7rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid black;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 30px;
    margin-left: 30%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .post {
    background-color: white;
    border: 1px solid black;
    min-height: 430px;
    width: 380px;
    text-align: center;
  }
}

.modal_content {

  .post_content {
    max-width: 28rem;
    margin-inline: auto;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      gap: .5rem;
    }

    .post_text_wrapper {
      padding: .5rem .8rem;

      p {
        margin: 0;
      }
    }

    .post_image_wrapper {
      border: 1px solid gray;

      .post_image {
        width: 100%;
      }
    }

    .edit_post_button {
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        position: absolute;
        left: 102%;
        top: 0
      }
    }
  }

  .post_image {
    height: 300px !important;
  }

  .regenrate_buttons_container {
    margin-bottom: .5rem;
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .regenerate_btn {
      margin-inline: .3rem;
      border: none;
      background: #06b050;
      color: #fff;
      padding: .3rem 1.5rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .social_media_buttons_container {
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .social_post_btn {
      margin-inline: .3rem;
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem .7rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid black;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 30px;
    margin-left: 30%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .post {
    background-color: white;
    border: 1px solid black;
    min-height: 430px;
    width: 380px;
    text-align: center;
  }
}
</style>
